<template>
  <div class="text-center">
    <crud :modal="tetikle_firma" @hideDialog="(i) => FirmashowClose(i)"></crud>
    <v-dialog persistent v-model="modal" width="900">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Modüller </v-card-title>

        <v-card-text class="mt-5">
          <v-form>
            <v-row class="mt-0 ml-1 mb-5">
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-card elevation="4" class="ma-2 pa-7">
                  <v-card-title class="mt-0">Temel Bilgiler</v-card-title>
                  <AutoComplete
                    :value="firma_id"
                    :items="firmalar"
                    itemText="unvan"
                    itemValue="id"
                    label="Firma"
                    clearable
                    :button_aktif="true"
                    :button_aktif_callback="hizli_firma_ekle"
                    @changed="(i) => firma_bilgisi_degisti(i)"
                  />
                  <v-autocomplete
                    v-model="prog_id"
                    :items="programlar"
                    itemText="ad"
                    itemValue="id"
                    outlined
                    dense
                    label="Program"
                  ></v-autocomplete>
                  <v-textarea
                    v-model="aciklama"
                    label="Açıklama"
                    auto-grow
                    outlined
                    rows="1"
                    row-height="15"
                  ></v-textarea>
                </v-card>
                <v-card elevation="4" class="ma-2 pa-7">
                  <v-card-title class="mt-0">Modüller</v-card-title>
                  <v-row>
                    <v-col
                      v-for="item in moduller"
                      :key="item.id"
                      cols="5"
                      sm="6"
                      md="6"
                    >
                      <v-checkbox
                        v-model="secilen_moduller"
                        :label="item.ad"
                        color="success"
                        :value="item.id"
                        hide-details
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-card elevation="4" class="ma-2 pa-7">
                  <v-card-title class="mt-0">Lisans Bilgileri</v-card-title>
                  <v-text-field
                    v-model="gelenKod"
                    label="KeyCode"
                    name="ad"
                    id="ad"
                    outlined
                  ></v-text-field>
                  <v-text-field
                    v-model="tarih"
                    label="YYYY-MM-GG"
                    name="ad"
                    id="ad"
                    outlined
                  ></v-text-field>
                  <v-text-field
                    v-model="yazilan_gun"
                    type="number"
                    label="Lisans Süresi"
                    name="ad"
                    id="ad"
                    outlined
                  ></v-text-field>
                  <v-text-field
                    v-model="terminal_sayisi"
                    type="number"
                    label="Terminal Sayısı"
                    name="ad"
                    id="ad"
                    outlined
                  ></v-text-field>
                  <v-text-field
                    v-model="sonuc"
                    label="Yeni Key"
                    name="ad"
                    id="ad"
                    outlined
                  ></v-text-field>
                  <v-btn block color="primary" @click="tersine(false)">
                    Yeni Key Oluştur
                  </v-btn>
                </v-card>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" dark @click="$emit('hideDialog')">Vazgeç</v-btn>
          <v-btn depressed color="primary" @click="kaydet"> Kaydet </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import {
  lisans_on_kayit_bilgisi_guncelle,
  lisansa_ait_moduller_listesi,
} from "../../query/lisanslar";
import { program_listesi, modul_listesi } from "../../query/program_moduller";
import AutoComplete from "../../components/fields/AutoComplete.vue";
import { firmalarListesi } from "../../query/firmalar";
import Crud from "../Firmalar/Crud.vue";
export default {
  methods: {
    async tersine(sadece_coz) {
      if (this.gelenKod) {
        let cozulen_bilgiler = [];
        cozulen_bilgiler = [];
        const uclu_parcalar = this.gelenKod.split("-");
        const anahtar = uclu_parcalar[4].toString();
        const anahtar_sayi = parseInt(anahtar, 16);
        let ilk_uclu_asil = "";
        for (let i = 0; i < uclu_parcalar[0].toString().length; i++) {
          const sayi = parseInt(uclu_parcalar[0].toString()[i].toString(), 16);
          const sayi_birinci_mod = (sayi - anahtar_sayi) % 16;
          const sayi_ikinci_mod =
            sayi_birinci_mod < 0 ? 16 + sayi_birinci_mod : sayi_birinci_mod;
          ilk_uclu_asil += sayi_ikinci_mod.toString(16).toUpperCase();
        }

        let ikinci_uclu_asil = "";
        for (let i = 0; i < uclu_parcalar[1].toString().length; i++) {
          const sayi = parseInt(uclu_parcalar[1].toString()[i].toString(), 16);
          const sayi_birinci_mod = (sayi - anahtar_sayi) % 16;
          const sayi_ikinci_mod =
            sayi_birinci_mod < 0 ? 16 + sayi_birinci_mod : sayi_birinci_mod;
          ikinci_uclu_asil += sayi_ikinci_mod.toString(16).toUpperCase();
        }

        let ucuncu_uclu_asil = "";
        for (let i = 0; i < uclu_parcalar[3].toString().length; i++) {
          const sayi = parseInt(uclu_parcalar[3].toString()[i].toString(), 16);
          const sayi_birinci_mod = (sayi - anahtar_sayi) % 16;
          const sayi_ikinci_mod =
            sayi_birinci_mod < 0 ? 16 + sayi_birinci_mod : sayi_birinci_mod;
          ucuncu_uclu_asil += sayi_ikinci_mod.toString(16).toUpperCase();
        }

        let otuzgun_sezar_asil = "";
        for (let i = 0; i < uclu_parcalar[2].toString().length; i++) {
          const sayi = parseInt(uclu_parcalar[2].toString()[i].toString(), 16);
          const sayi_birinci_mod = (sayi - anahtar_sayi) % 16;
          const sayi_ikinci_mod =
            sayi_birinci_mod < 0 ? 16 + sayi_birinci_mod : sayi_birinci_mod;
          otuzgun_sezar_asil += sayi_ikinci_mod.toString(16).toUpperCase();
        }

        let terminal_asil_sezar = "";
        for (let i = 0; i < uclu_parcalar[5].toString().length; i++) {
          const sayi = parseInt(uclu_parcalar[5].toString()[i].toString(), 16);
          const sayi_birinci_mod = (sayi - anahtar_sayi) % 16;
          const sayi_ikinci_mod =
            sayi_birinci_mod < 0 ? 16 + sayi_birinci_mod : sayi_birinci_mod;
          terminal_asil_sezar += sayi_ikinci_mod.toString(16).toUpperCase();
        }

        cozulen_bilgiler.push(
          ilk_uclu_asil +
            "-" +
            ikinci_uclu_asil +
            "-" +
            otuzgun_sezar_asil +
            "-" +
            ucuncu_uclu_asil +
            "-" +
            anahtar +
            "-" +
            terminal_asil_sezar
        );
        cozulen_bilgiler.push(parseInt(otuzgun_sezar_asil, 16).toString());
        cozulen_bilgiler.push(parseInt(terminal_asil_sezar, 16).toString());

        this.tarih_farki_hesapla();
        if (sadece_coz === false) {
          const deneme = this.yeni_key_olustur(
            cozulen_bilgiler[0],
            this.olusan_gun_sayisi
          );
          this.sonuc = deneme;
        } else {
          this.yazilan_gun = parseInt(otuzgun_sezar_asil, 16).toString();
          this.terminal_sayisi = parseInt(terminal_asil_sezar, 16).toString();
        }
      }
    },
    yeni_key_olustur(gelen_kod, kac_gun) {
      let ucluler = "";
      let ucluler_asil = "";
      const parcala = gelen_kod.split("-");
      const ilk_uclu = parcala[0];
      const ikinci_uclu = parcala[1];
      const ucuncu_uclu = parcala[3];
      const otuz_gun = kac_gun.toString(16).toUpperCase().padStart(3, "0");
      const bu_terminal_sayisi = Number(this.terminal_sayisi)
        .toString(16)
        .toUpperCase()
        .padStart(3, "0");
      while (ucluler_asil === ucluler) {
        const rastgele_sayi = Math.floor(Math.random() * 999999999) + 9999;
        const rastgele_hex = rastgele_sayi.toString(16).toUpperCase();
        const rastgele_uclu = rastgele_hex.substring(0, 3);
        const revize_rastgele_sayi = parseInt(rastgele_uclu, 16);

        ucluler_asil = `${ilk_uclu}-${ikinci_uclu}-${otuz_gun}-${ucuncu_uclu}-${rastgele_uclu}-${bu_terminal_sayisi}`;

        let ilk_uclu_sezar = "";
        for (let i = 0; i < ilk_uclu.length; i++) {
          const sayi = parseInt(ilk_uclu[i], 16);
          ilk_uclu_sezar += ((sayi + revize_rastgele_sayi) % 16)
            .toString(16)
            .toUpperCase();
        }

        let ikinci_uclu_sezar = "";
        for (let i = 0; i < ikinci_uclu.length; i++) {
          const sayi = parseInt(ikinci_uclu[i], 16);
          ikinci_uclu_sezar += ((sayi + revize_rastgele_sayi) % 16)
            .toString(16)
            .toUpperCase();
        }

        let ucuncu_uclu_sezar = "";
        for (let i = 0; i < ucuncu_uclu.length; i++) {
          const sayi = parseInt(ucuncu_uclu[i], 16);
          ucuncu_uclu_sezar += ((sayi + revize_rastgele_sayi) % 16)
            .toString(16)
            .toUpperCase();
        }

        let otuz_gun_sezar = "";
        for (let i = 0; i < otuz_gun.length; i++) {
          const sayi = parseInt(otuz_gun[i], 16);
          otuz_gun_sezar += ((sayi + revize_rastgele_sayi) % 16)
            .toString(16)
            .toUpperCase();
        }
        let terminal_sezar = "";
        for (let i = 0; i < bu_terminal_sayisi.length; i++) {
          const sayi = parseInt(bu_terminal_sayisi[i], 16);
          terminal_sezar += ((sayi + revize_rastgele_sayi) % 16)
            .toString(16)
            .toUpperCase();
        }

        ucluler = `${ilk_uclu_sezar}-${ikinci_uclu_sezar}-${otuz_gun_sezar}-${ucuncu_uclu_sezar}-${rastgele_uclu}-${terminal_sezar}`;
      }

      return ucluler;
    },
    tarih_farki_hesapla() {
      // const gunun_tarihi = moment();
      // const baslangic_tarihi = moment(this.tarih);
      // const fark = gunun_tarihi.diff(baslangic_tarihi, "days");
      // console.log(fark);
      this.olusan_gun_sayisi = parseInt(this.yazilan_gun);
    },
    async kaydet() {
      console.log(this.lisans_no, this.sonuc);
      if (this.sonuc === null) {
        this.sonuc = this.gelenKod;
      }

      await lisans_on_kayit_bilgisi_guncelle(
        this.sonuc,
        this.prog_id,
        this.firma_id,
        this.tarih,
        this.secilen_moduller,
        this.aciklama,
        this.lisans_id
      );
      this.$swal.fire("Başarılı!", "Yeni Lisans Oluştu", "success");
      this.$emit("hideDialog");
    },
    firma_bilgisi_degisti(i) {
      this.firma_id = i;
    },
    hizli_firma_ekle() {
      this.tetikle_firma = true;
    },
    async FirmashowClose(i) {
      this.tetikle_firma = false;
      this.firmalar = await firmalarListesi();
      this.firma_id = i;
    },
  },
  props: { modal: null, veriler: null },
  data() {
    return {
      gelenKod: "",
      yazilan_gun: 0,
      tarih: null,
      sonuc: null,
      olusan_gun_sayisi: 0,
      terminal_sayisi: 0,
      programlar: null,
      prog_id: null,
      firmalar: null,
      firma_id: null,
      lisans_id: null,
      tetikle_firma: null,
      secilen_moduller: [],
      moduller: null,
      aciklama: null,
    };
  },
  async mounted() {},

  watch: {
    async modal(val) {
      if (val) {
        this.sonuc = null;
        this.moduller = await modul_listesi();
        this.programlar = await program_listesi();
        this.firmalar = await firmalarListesi();
        this.lisans_id =
          this.veriler?.id ?? "00000000-0000-0000-0000-000000000000";
        this.gelenKod = this.veriler?.lisans_no;
        this.aciklama = this.veriler?.aciklama;
        this.prog_id = this.veriler?.prog_id;
        this.firma_id = this.veriler?.firma_id;
        this.tarih = moment(this.veriler?.lisans_kayit_tarihi).format(
          "YYYY-MM-GG"
        );
        const gelen_moduller = await lisansa_ait_moduller_listesi(
          this.lisans_id
        );
        const son_liste = gelen_moduller.map((item) => item.id);
        this.secilen_moduller = son_liste;
        this.tersine(true);
      }
    },
  },
  components: { AutoComplete, Crud },
};
</script>

<style></style>

import { ApiClient } from "../helpers/api-client";

const apiClient = new ApiClient();

const program_listesi = async () => {
  return await apiClient.makeRequest({
    url: `${process.env.VUE_APP_API_URL}/program_listesi`,
  });
};
const modul_listesi = async () => {
  return await apiClient.makeRequest({
    url: `${process.env.VUE_APP_API_URL}/modul_listesi`,
  });
};

export { program_listesi, modul_listesi };

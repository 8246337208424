import { ApiClient } from "../helpers/api-client";

const apiClient = new ApiClient();

const lisans_on_kayit_listesi = async () => {
  return await apiClient.makeRequest({
    url: `${process.env.VUE_APP_API_URL}/lisans_listesi`,
  });
};

const lisansa_ait_moduller_listesi = async (id) => {
  return await apiClient.makeRequest({
    url: `${process.env.VUE_APP_API_URL}/lisansa_ait_moduller/${id}`,
  });
};

const lisans_on_kayit_bilgisi_guncelle = async (
  lisans_no,
  program_id,
  firma_id,
  lisans_kayit_tarihi,
  secilen_moduller,
  aciklama,
  id
) => {
  return await apiClient.makeRequest({
    url: `${process.env.VUE_APP_API_URL}/lisans_guncelle/${id}`,
    json: {
      lisans_no,
      program_id,
      firma_id,
      lisans_kayit_tarihi,
      secilen_moduller,
      aciklama,
      id,
    },
  });
};

const lisans_on_kayit_bilgisi_sil = async (id) => {
  return await apiClient.makeRequest({
    url: `${process.env.VUE_APP_API_URL}/lisans_sil/${id}`,
  });
};

export {
  lisans_on_kayit_listesi,
  lisans_on_kayit_bilgisi_guncelle,
  lisans_on_kayit_bilgisi_sil,
  lisansa_ait_moduller_listesi,
};

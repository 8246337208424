<template>
  <layout>
    <template slot="main">
      <moduller
        :modal="tetikle_moduller"
        :veriler="gidecek_data"
        @hideDialog="showClose()"
      ></moduller>
      <v-card class="mt-6">
        <v-card-title>
          Lisanslar
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="
              tetikle_moduller = true;
              gidecek_data = null;
            "
          >
            Yeni Lisans
          </v-btn>
        </v-card-title>
        <datatables :headers="headers" :items="firmalar">
          <template v-slot:dt_actions="{ item }">
            <v-btn color="success" dark small @click="yeni_bilgiler(item)">
              Lisans Bilgileri
            </v-btn>
            <v-btn
              class="ml-2"
              color="red"
              dark
              small
              @click="pasif_et(item.id)"
            >
              Pasif Et
            </v-btn>
          </template>
          <template v-slot:dt_lisans_kayit_tarihi="{ item }">
            {{ item.lisans_kayit_tarihi | moment("DD-MM-YYYY") }}
          </template>
        </datatables>
      </v-card>
    </template>
  </layout>
</template>

<script>
import Datatables from "../../components/datatables/index.vue";
import {
  lisans_on_kayit_listesi,
  lisans_on_kayit_bilgisi_sil,
} from "../../query/lisanslar";
import Layout from "@/components/Layout";
import Moduller from "./Moduller";
export default {
  components: { Datatables, Layout, Moduller },

  async mounted() {
    this.firmalar = await lisans_on_kayit_listesi();
  },

  methods: {
    async silDialog(item) {
      const result = await this.$dialog.error({
        text: "Kayıt silinsin mi?",
        title: "Dikkat",
        actions: {
          true: {
            color: "error",
            text: "Evet",
          },
          false: "Hayır",
        },
      });
      if (result) {
        console.log(result, item);
        // const sonuc = await firmaSil(item.id);
        // if (sonuc !== undefined) {
        //   this.firmalar = await lisans_on_kayit_listesi();
        // }
      }
    },
    yeni_bilgiler(item) {
      this.gidecek_data = item;
      this.tetikle_moduller = true;
    },
    async showClose() {
      this.tetikle_moduller = false;
      this.firmalar = await lisans_on_kayit_listesi();
    },
    async pasif_et(id) {
      await lisans_on_kayit_bilgisi_sil(id);
      this.firmalar = await lisans_on_kayit_listesi();
    },
  },

  watch: {
    async tetikle(val) {
      if (val === false) {
        this.firmalar = await lisans_on_kayit_listesi();
      }
    },
  },

  data() {
    return {
      firmalar: [],
      tetikle: false,
      tetikle_moduller: null,
      gidecek_data: null,
      gelen_id: null,
      headers: [
        {
          text: "Demo Başlangıç Tarihi",
          value: "lisans_kayit_tarihi",
          filterType: "string",
          hasSlot: true,
        },
        {
          text: "Firma",
          value: "unvan",
          filterType: "string",
        },
        {
          text: "Gsm",
          value: "gsm",
          filterType: "string",
        },
        {
          text: "İl",
          value: "il_ad",
          filterType: "string",
        },
        {
          text: "İlçe",
          value: "ilce_ad",
          filterType: "string",
        },
        {
          text: "Program",
          value: "program",
          filterType: "string",
        },
        {
          text: "Modüller",
          value: "moduller",
          filterType: "string",
        },
        {
          text: "Key",
          value: "lisans_no",
          filterType: "string",
        },

        {
          text: "İŞLEMLER",
          value: "actions",
          hasSlot: true,
        },
      ],
    };
  },
};
</script>
